.centerView {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 25rem;
    width: 35rem;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 18px;
    margin-left: 2rem;
    margin-right: 2rem;
}

.loginHeader {
    font-family: "Geometria-Medium";
    font-weight: 600;
    font-size: 1.7rem;
    margin-bottom: 3rem;
    text-align: center;
}