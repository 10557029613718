@import '../../../constants/fonts.css';

.button {
    font-family: "Geometria-Medium";
    font-size: 16px;
    font-weight: 500;
    color: white;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-height: 40px;
    height: 40px;
    border-radius: 25px;
    background-color: var(--primary);
    border: none;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.25s;
    padding: 0.15rem 1.4rem 0 1.4rem;
}

.button:hover {
    background-color: var(--primary-hover);
}