body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toggle.react-toggle--checked .react-toggle-track {
  background-color: var(--primary);
}

.toggle .react-toggle-thumb {
  transform: scale(1.08);
  box-shadow: 0px 0px 5px #c1c1c1 !important;
}

.toggle .react-toggle-track {
  background-color: var(--grey);
}

.toggle .react-toggle-thumb {
  border: none;
}

.toggle.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--grey-hover);
}

.toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--primary-hover);
}

.toggle.react-toggle:active .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px transparent !important;
}