.centerView {
    height: 100vh;
    min-height: 530px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preferencesContainer {
    display: grid;
    grid-template-rows: 72px auto 82px;
    width: 35rem;
    min-width: 320px;
    height: 540px;;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 16px;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-left: 1.85rem;
    padding-right: 1.85rem;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardHeaderText {
    font-family: 'Geometria-Medium';
    font-size: 1.15rem;
    font-weight: 700;
    padding-top: .3rem;
}

.cardHeaderClose {
    /* Don't display the close icon, as it has no use at the moment */
    visibility: hidden;
}

.cardContent {
    font-family: "Geometria";
    height: 100%;
    overflow: auto;
}

.cardFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: fixed;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 25px;
    /* left: 39px; */
    height: 25px;
    width: 25px;
}

.spinner {
    /* transition: all 0.3s; */
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}

.diagonal.part1 {
    position: relative;
    float: left;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}
.diagonal.part2 {
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
    transform: rotate(-135deg);
    margin-top: -9px;
}

.table {
    /* margin-top: 3rem; */
}

.table tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.023);
}

.tableColHeader {
    opacity: .3;
    font-size: .8rem;
    font-family: 'Geometria-Medium';
}

.tableRowHeader {
    font-size: .9rem;
    font-family: 'Geometria-Medium';
}

.tableRowHeaderDesc {
    font-size: .8rem;
    font-weight: normal;
}

.tableItemRow {
    height: 3.5rem;
}

.tableItemRow > td, .tableItemRow > th {
    vertical-align: middle;
}

@media (max-width: 480px) {
    .preferencesContainer {
      grid-template-rows: 72px auto 172px;
      height: 100vh;
      border-radius: 0;
      margin-left: 0;
      margin-right: 0;
    }
}

@media only screen and (min-width: 481px)  {
    .tableColHeaderItem {
        width: 23%;
        text-align: center;
    }
    .tableColItem {
        text-align: center;
    }
}

.cancelButton {
    font-family: "Geometria-Medium";
    font-weight: 600;
    background-color: transparent;
    color: black;
    font-size: .8rem;
}

.cancelButton:hover {
    background-color: transparent;
    color: black;
}

.submit {
    text-align: right;
}

.submitButton {
    font-family: "Geometria-Medium";
    font-weight: 600;
    font-size: .8rem;
    border-radius: 25px;
}

.statusMessage {
    margin-top: .4rem;
    font-size: .9rem;
    opacity: .3;
    font-weight: 600;
    font-family: "Geometria";
}