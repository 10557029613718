:root {
  --fs-8: 0.5rem;
  --fs-12: 0.75rem;
  --fs-16: 1rem;
  --fs-20: 1.25rem;
  --fs-24: 1.5rem;
  --fs-28: 1.75rem;
}

@font-face {
  font-family: "Geometria";
  src: url("../assets/fonts/Geometria.woff") format("woff");
}

@font-face {
  font-family: "Geometria-Medium";
  src: url("../assets/fonts/Geometria-Medium.woff") format("woff")
}

@font-face {
  font-family: "Geometria-Bold";
  src: url("../assets/fonts/Geometria-Bold.woff") format("woff")
}